import { Geolocation, Grid, Loading, NAVIGATION_ACTION } from '@qsite/components';
import { appointmentActions, appSettingsActions, locationActions, useStore } from '@qsite/services';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocation } from '../../services/api/location';
import { APP_ROUTES } from '../../utils/routesConfig';
import LocationList from './LocationList/LocationList';
import LocationSearchInput from './LocationSearchInput/LocationSearchInput';
import MapContainer from './MapContainer/MapContainer';

export default function LocationSearchContainer(props) {
	const { setTitle, setDescription } = props;
	const [state, dispatch] = useStore();
	const SETTINGS = state.qsiteSettings;
	const i18n = useTranslation();
	const [loading, setLoading] = useState(false);
	const [locationDefaults, setLocationDefaults] = useState({ latitude: 0, longitude: 0 });
	const [locations, setLocations] = useState({});
	const [coordinates, setCoordinates] = useState(null);
	const [inputAddress, setInputAddress] = useState('');

	useEffect(() => {
		setLoading(false);
		dispatch(appointmentActions.resetAppointment());
	}, [dispatch, state.qsiteSettings]);

	const getLocations = useCallback((coords) => {
		function changeHeader(hasLocation) {
			if (hasLocation === false) {
				setTitle(SETTINGS.locations.title || null);
				setDescription(SETTINGS.locations.description || null)
			} else {
				setTitle(SETTINGS.location.title || null);
				setDescription(SETTINGS.location.description || null)
			}
		}

		setLoading(true);
		setLocations({
			count: 0,
			list: [],
		});
		if (!!coords) {
			setCoordinates(coords);
			changeHeader(true);
			setLocationDefaults({
				latitude: coords.latitude,
				longitude: coords.longitude,
			});
			getLocation({
				params: {
					near: `${coords.latitude},${coords.longitude}`,
					mi: SETTINGS.locations.distance,
					limit: SETTINGS.locations.limit,
					show_next_avail_appt: 1,
				},
				headers: {},
			})
				.then((res) => {
					setLoading(false);
					setLocations({
						count: res.data.data.totalCount,
						list: res.data.data.location,
					});
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [SETTINGS.location.description, SETTINGS.location.title,
	SETTINGS.locations.description, SETTINGS.locations.distance,
	SETTINGS.locations.limit, SETTINGS.locations.title,
		setDescription, setTitle]);

	function applyGeolocation(e) {
		e.preventDefault();
		e.stopPropagation(); setLoading(true);
		if (navigator.geolocation) {
			window.navigator.geolocation.getCurrentPosition(
				(position) => {
					setLocationDefaults({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					});
					setLoading(false);
					getLocations({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					});
				},
				(error) => {
					setLoading(false);
					window.alert(i18n.t('location.denied_access'));
				}
			);
		} else {
			setLoading(false);
			window.alert(i18n.t('location.geo_not_available'));
		}
	}

	function updateLocation(loc) {
		dispatch(locationActions.setLocation(loc));
		dispatch(appSettingsActions.setProgress({ path: APP_ROUTES.DEVICE_ID, method: NAVIGATION_ACTION.PUSH }));
	}

	return (<>
		<LocationSearchInput
			updateLocation={getLocations}
			loadingText={i18n.t('general.loading')}
			loading={loading}
			address={inputAddress}
			setAddress={setInputAddress}
			spinnerDisabled = {SETTINGS?.theme?.spinner?.disabled}
		/>
		<Geolocation applyGeolocation={applyGeolocation} useMyLocation={i18n.t('location.use_my_location')} customStyle={SETTINGS?.theme} />
		{coordinates ? loading ? (SETTINGS?.theme?.spinner?.disabled ? '' : <Loading screenReaderText={i18n.t('general.loading_page')} />)
			: (
				<Grid gridTemplateColumns='1fr 1fr' mobileGridTemplateColumns='1fr' gridWidth='100%'>
					<MapContainer
						latLong={locationDefaults}
						list={locations.list}
						getLocations={getLocations}
						spinnerDisabled = {SETTINGS?.theme?.spinner?.disabled}
					/>
					<LocationList
						color={SETTINGS.theme.color.primary}
						list={locations ? locations.list : null}
						updateLoc={updateLocation}
						hasShop={!!locations.count}
						SETTINGS={SETTINGS}
						setCoordinates={setCoordinates}
						setAddress={setInputAddress}
					/>
				</Grid>
			) : null}
	</>)
}
import { Loading } from '@qsite/components';
import { GoogleApiWrapper } from 'google-maps-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './LocationSearchInput.scss';

export function LocationSearchInput(props) {
	const i18n = useTranslation();
	const { address, setAddress } = props;

	function handleSelect(addr) {
		const { updateLocation } = props;
		geocodeByAddress(addr)
			.then((res) => {
				setAddress(res[0].formatted_address);
				return getLatLng(res[0]);
			})
			.then((latLng) => {
				updateLocation({
					latitude: latLng.lat,
					longitude: latLng.lng,
				});
			})
			.catch((error) => console.error(error));
	}

	return (
		<PlacesAutocomplete
			value={address}
			onChange={setAddress}
			onSelect={handleSelect}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div className="location_search_container">
					<input
						{...getInputProps({
							placeholder: `${i18n.t('location.search_placeholder')}`,
							className: 'location_search_input',
						})}
					/>
					<i className="fas fa-search" />
					<div className="autocomplete_dropdown_container">
						{loading && <div>{props.loadingText}</div>}
						{suggestions.map((suggestion, i) => {
							const className = 'suggestion_item';
							// inline style for demonstration purpose
							const style = suggestion.active
								? { backgroundColor: '#fafafa', cursor: 'pointer' }
								: { backgroundColor: '#ffffff', cursor: 'pointer' };

							return (
								<div
									{...getSuggestionItemProps(suggestion, {
										className,
										style,
									})}
									id={`suggestion_item--${i + 1}`}
									key={suggestion.placeId}
								>
									<span>{suggestion.description}</span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
}


export default GoogleApiWrapper((props) => ({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
	LoadingContainer: !props.spinnerDisabled ? Loading : null,
}))(LocationSearchInput);

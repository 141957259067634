import api from '.';
import queryBuilder from '../../utils/queryHelper';

export function getCatalogItems(categoryID, locationID) {
	if (!categoryID) {
		throw new Error('Category ID null or undefined');
	}

	if (!locationID) {
		throw new Error('Location ID null or undefined');
	}

	return api.get(`/category/details/${categoryID}?incl_catalog=1&prune_empty=1&incl_children=1&catalog_is_repairable=1&catalog_is_labor=0&catalog_is_part=0&child_levels=0&catalog_loc=${locationID}`, {
		timeout: 60000,
	});
}

export function getDeviceIMEI(deviceIMEI) {
	const queryParams = queryBuilder([`imei=${deviceIMEI}`]);
	return api.get(`/ticket/bbti_validate_imei${queryParams}`);
}

export function getCompatibleServices({ locationID, catalogItemID, filter }) {
	if (!locationID) throw new Error("Can't get available services - locationID is null or undefined")
	if (!catalogItemID) throw new Error("Can't get available services - catalogItemID is null or undefined")

	filter = filter ? '&' + filter : ''

	return api.get(
		`/catalogItem?is_advertised=1${filter}&location=${locationID}&parent_catalog_item_id=${catalogItemID}&limit=0`
	);
}

export function getOffer(filter) {
	let queryArr = [];
	let location = 1;
	let program = 'telstra-overtrade';

	if (location) queryArr.push(`location=${location}`);
	if (program) queryArr.push(`program=${program}`);
	if (filter) queryArr.push(`filter=${filter}`);

	const queryParams = queryBuilder(queryArr);

	const url = `/catalogItem/bbti_price${queryParams}`;
	return api.get(url);
}

export default { getCatalogItems, getCompatibleServices, getOffer };

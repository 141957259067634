import { Loading } from '@qsite/components';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import './MapContainer.scss';

export function MapContainer(props) {
	const { google, list, latLong } = props;
	const { latitude, longitude } = latLong;
	const [bounds, setBounds] = useState({});

	useEffect(() => {
		const points = [];
		for (let i = 0; i < list.length; i++) {
			const obj = {
				lat: Number(list[i].geo_lat),
				lng: Number(list[i].geo_lon),
			};
			points.push(obj);
		}

		const b = new google.maps.LatLngBounds();
		for (let i = 0; i < points.length; i++) {
			b.extend(points[i]);
		}

		setBounds(bounds);
	}, [bounds, google.maps.LatLngBounds, list]);

	let lat = latitude;
	let lon = longitude;

	if (!list[0]) {
		return (
			<div className="map_container">
				<div className="map_wrap">
					<Map
						google={google}
						initialCenter={{
							lat,
							lng: lon,
						}}
						center={{
							lat,
							lng: lon,
						}}
						bounds={bounds}
					/>
				</div>
			</div>
		);
	}

	if (latitude === 0 && longitude === 0) {
		lat = Number(list[0].geo_lat);
		lon = Number(list[0].geo_lon);
	} else {
		lat = latitude;
		lon = longitude;
	}

	return (
		<div className="map_container" data-testid="map-container">
			<div className="map_wrap">
				<Map google={google}
					initialCenter={{ lat, lng: lon }}
					center={{ lat, lng: lon }}
					zoom={8}
					bounds={bounds}
					mapTypeControl={false}
					streetViewControl={false}
					rotateControl={false}
					fullscreenControl={false}
				>
					{list.map((loc) => (
						<Marker
							key={loc.id}
							name={loc.id}
							position={{
								lat: Number(loc.geo_lat),
								lng: Number(loc.geo_lon),
							}}
						/>
					))}
				</Map>
			</div>
		</div>
	);
}

export default GoogleApiWrapper((props) => ({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
	LoadingContainer: !props.spinnerDisabled ? Loading : null,
}))(MapContainer);

export default {
	general: {
		continue: 'Continue',
		sell_another_device: 'Sell Another Device',
		yes: 'Yes',
		no: 'No',
		select: 'Select',
		loading: 'Loading...',
		loading_page: 'Loading page',
		start_of_dialog: 'Start of Dialog',
		end_of_dialog: 'End of Dialog',
		breadcrumbs_aria_label: 'breadcrumbs',
		read_more: 'Read More',
		read_less: 'Read Less',
	},
	pageTitles: {
		appTitle: 'Qsite',
		location: 'Location',
		deviceIdentification: 'Device Identification',
		contactInfo: 'Contact Information',
		quote: 'Quote',
		appointment: 'Appointment',
		appointmentConfirmation: 'Appointment Confirmation',
		appointmentConfirmed: 'Appointment Confirmed',
		appointmentCancellation: 'Appointment Cancellation',
		appointmentCancelled: 'Appointment Cancelled',
		ticket: 'Ticket',
		ticketLookup: 'Ticket Lookup',
		rejected: 'Rejected'
	},
	validations: {
		required: 'This field is required.',
		only_letters: 'Only letters allowed',
		only_numbers: 'Only numbers allowed',
		must_be_length: 'Must be {{length}} characters long',
		min_length: 'A minimum of {{length}} character long is needed',
		invalid_email: 'Invalid email',
		invalid_only_whitespace: 'Only space is not allowed',
	},
	location: {
		use_my_location: 'Use my current location',
		geo_not_available: 'Geolocation not available in your browser. Please add your address manually.',
		denied_access: 'You denied access to your location',
		no_locations_found: 'No locations found within {{distance}} {{distance_unit}} of your location.',
		please_update: 'Please update your location or <1>click here</1> to refresh',
		search_placeholder: 'Address, City, or Postal Code',
		appt_time: 'Next Available Appt. Time',
		select_distance: '{{distance}} miles'
	},
	customerInfo: {
		get_a_quote: 'Submit Request',
		close: 'Close',
		terms_of_use: 'Terms of Use',
		terms_and_conditions: 'Terms and Conditions',
		i_agree: 'I Agree',
		first_name: 'First Name',
		last_name: 'Last Name',
		email: 'Email',
		phone_number: 'Phone Number',
		problem_description: 'Please enter your device model and a description of the problem',
		device_description: 'Please enter your device model and a description of the problem',
		receive_notifications: 'I consent to receiving electronic notifications for this service.',
		receive_marketing: 'I consent to receiving electronic messages about promotions, special offers and news about events and services.'
	},
	rejected: {
		unable_to_accept: 'Unable to Accept Device',
		thanks_for_completing: 'Thank you for completing the assessment of your device. However, based on the assessment you have completed, we are unable to accept your device.',
	},
	deviceIdentification: {
		identify_device: 'Identify Device',
		identify_device_desc: 'Let\'s get started! To get an accurate quote for your device, start by telling us what you have.',
		other: 'Other',
	},
	cancelSession: {
		title: 'Are you sure you want to cancel this session?',
		no: 'No, Continue',
		yes: 'Yes, Close'
	},
	errorScreen: {
		try_again_message: 'Or <1>click here</1> to try again',
		error_code: 'Error Code:',
		error_message: 'Error Message:',
		error_ref: 'Error Ref Number:',
	},
	appointment: {
		title: 'Select an appointment day and time',
		description: 'An appointment is recommended, but walk-ins are always welcome!',
		no_available_appt: 'Sorry, no available appointment times for the chosen date',
	},
	maintenance: {
		title: 'We\'re currently under maintenance.',
		description: 'Thanks for visiting us. We\'re currently performing scheduled maintenance and will be back soon',
	},
	appointmentConfirmation: {
		title: 'Please confirm your appointment details',
		day_time: 'Appointment Day/Time',
		contact_information: 'Contact Information',
		location: 'Location',
		device: 'Device',
		get_directions: 'Get Directions',
		confirm_appointment: 'Confirm Appointment',
		change_day_time: 'Change Day/Time'
	},
	appointmentConfirmed: {
		title: 'Thank you! Your appointment is scheduled',
		get_another_quote_button_label: 'Get Another Quote',
		cancel_appointment: 'Cancel Appointment',
		view_summary_button_label: 'View Summary',
	},
	appointmentCancellation: {
		title: 'Are you sure you want to cancel your appointment?',
		keep_appointment: 'Keep Appointment',
		cancel_appointment: 'Yes, Cancel Appointment',
	},
	appointmentCancelled: {
		title: 'Your appointment has been cancelled',
		get_another_appointment_button_label: 'Get Another Appointment',
		get_another_quote_button_label: 'Get Another Quote',
		view_summary_button_label: 'View Summary',
	},
	ticket: {
		title: 'Service Summary',
		description: 'View your service details below',
		problem_description: 'Problem Description',
		schedule_appointment: 'Schedule Appointment',
		cancel_appointment: 'Cancel Appointment',
		hello: 'Hello',
		ticket_number: 'Ticket #',
		notes: 'Notes',
		noNotes: 'There are no notes at this time.',
	},
	ticketLookup: {
		title: 'Ticket Lookup',
		description: 'Enter your ticket or authorization number below to receive a secure link',
		input_placeholder: 'Authorization Number',
		button_label: 'Send me a secure link',
		valid_ticketID: 'Secure link sent',
		invalid_ticketID: 'Ticket not found',
	},
	quote: {
		get_directions: 'Get Directions',
		repair: 'Repair',
		device: 'Device',
		problem: 'Problem',
		location: 'Location',
		schedule_appointment: 'Schedule an Appointment',
		get_another_quote: 'Get another quote',
	},
	breadcrumbs: {
		aria_label: 'breadcrumbs',
		identification: 'Identification', 
		appointment: 'Appointment',
		contact: 'Contact',
		quote: 'Quote',
		location: 'Location',  
		confirmation: 'Confirmation',
		cancellation: 'Cancellation',
	},
};
